import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Box, Themed } from "theme-ui"

const FieldsNewsSingle = ( props ) => {

  const data = useStaticQuery(
    graphql`
      query FieldsNewsSingle{
        dataJson(slug: {eq: "fields-news-single"}) {
          fragment_1
          fragment_2
          fragment_3
          fragment_4
        }
      }
    `
  )

  const date = props.date
  const language = props.language
  const medio = props.medio
  const source = props.source
  const urlsource = props.urlsource
  const dataFragments = data.dataJson

  return(

    <Box
      sx={{
        h2: {
          fontSize: 2,
        },
        ul: {
          listStyle: `none`,
          ml: 0,
          py: 2,
          px: 0,
        },
        li: {
          fontSize: 1,
          lineHeight: `none`,
        }
      }}
    >
      <Themed.ul>
        <Themed.li>{dataFragments.fragment_1}: {date}</Themed.li>
        <Themed.li>{dataFragments.fragment_2}: {language}</Themed.li>
        <Themed.li>{dataFragments.fragment_3}: {medio}</Themed.li>
        <Themed.li>{dataFragments.fragment_4}: <Themed.a href={`${urlsource}`} aria-label="link to external source" target="_blank" rel="noopener noreferrer">{source}</Themed.a></Themed.li>
      </Themed.ul>
    </Box>
  
  )

}

export default FieldsNewsSingle